var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex justify-start align-center flex-column pa-0",class:_vm.$style.auth,staticStyle:{"width":"100%","min-width":"320px","max-width":"320px"},attrs:{"fluid":"","fill-height":""}},[_c('v-overlay',{attrs:{"value":_vm.processing}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('migration-logo')],1)],1),_c('v-row',{staticClass:"mt-1 mb-4",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h2',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.title))])])],1),_c('v-row',{attrs:{"dense":""}},[(Object.keys(_vm.errorMessages).length)?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-card',{style:({
  backgroundColor: 'transparent'
}),attrs:{"flat":"","width":"300","background":"transparent"}},[_c('error-list',{attrs:{"errorMessages":_vm.errorMessages,"names":['message']}})],1)],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-1 pa-5 mb-3 rounded-xl",class:_vm.$style.form,attrs:{"color":"#fff","rounded":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-center py-1 pa-3 mb-3",staticStyle:{"font-weight":"600","color":"red"}},[_vm._v(" メールアドレス変更のお願い ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{class:[_vm.$style.form_msg, 'text-center', 'py-1', 'pa-3', 'mb-3'],staticStyle:{"font-weight":"normal !important"}},[_vm._v(" JaM id をApple の非公開メールアドレス"),_c('br'),_vm._v(" で利用されているため、切替手続きを開"),_c('br'),_vm._v(" 始できませんでした。"),_c('br')]),_c('h3',{class:[_vm.$style.form_msg, 'py-1', 'pa-3', 'mb-3'],staticStyle:{"font-weight":"normal !important"}},[_vm._v(" 以下のフォームからJaM id アカウントの"),_c('br'),_vm._v(" メールアドレスを一時的に変更し、"),_c('br'),_vm._v(" JRE ID への切替を再開してください。"),_c('br'),_vm._v(" （サインイン方法はApple のまま変わり"),_c('br'),_vm._v(" ません） ")]),_c('h3',{class:[_vm.$style.form_msg, 'py-1', 'pa-3', 'mb-3']},[_vm._v(" 変更後のJaM id メールアドレスが、そ"),_c('br'),_vm._v(" のまま新規作成するJRE ID の登録メー"),_c('br'),_vm._v(" ルアドレスに引き継がれます。"),_c('br')])]),_c('v-col',{attrs:{"cols":"12"}},[_c('MyForm',{ref:"form",attrs:{"formKey":_vm.formKey,"entity":_vm.entity,"input":_vm.input}},[_c('v-container',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"v-newMailAddress",attrs:{"rules":{
  required: 'required',
  email: 'email',
  emailEndDomain: []
},"name":"新しいメールアドレス","vid":"newMailAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:_vm.$style.textfield,attrs:{"name":"newMailAddress","label":"新しいメールアドレス New Email Address","clearable":"","autocomplete":"off","error-messages":errors[0],"background-color":"#fff"},model:{value:(_vm.input['newMailAddress']),callback:function ($$v) {_vm.$set(_vm.input, 'newMailAddress', $$v)},expression:"input['newMailAddress']"}})]}}])})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('base-btn',{style:({
  color: 'white',
  fontSize: '1rem',
  boxShadow: 'none'
}),attrs:{"disabled":_vm.btnDisabled,"label":"「メールアドレスを変更する」"},on:{"click":_vm.onConfirm}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{style:({
  'background-color': '#ffb95d', 'color': '#fff',
  fontSize: '1rem',
  boxShadow: 'none'
}),attrs:{"rounded":"","block":"","large":""},on:{"click":_vm.onStop}},[_vm._v("メールアドレスを変更しない"),_c('br'),_vm._v("※前ページに戻ります")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }