<template>
  <v-container fluid fill-height :class="$style.auth" class="d-flex justify-start align-center flex-column pa-0"
    style="width: 100%; min-width: 320px; max-width: 320px;">
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <migration-logo />
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-4">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center align-center" v-if="Object.keys(errorMessages).length">
          <v-card flat width="300" background="transparent" :style="{
    backgroundColor: 'transparent'
  }">
            <error-list :errorMessages="errorMessages" :names="['message']" />
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card color="#fff" class="py-1 pa-5 mb-3 rounded-xl" rounded :class="$style.form">
            <v-row dense>
              <v-col cols="12">
                <h3 style="font-weight: 600; color: red;" class="text-center py-1 pa-3 mb-3">
                  メールアドレス変更のお願い
                </h3>
              </v-col>
              <v-col cols="12">
                <h3 :class="[$style.form_msg, 'text-center', 'py-1', 'pa-3', 'mb-3']" style="font-weight: normal !important;">
                  JaM id をApple の非公開メールアドレス<br>
                  で利用されているため、切替手続きを開<br>
                  始できませんでした。<br>
                </h3>
                <h3 :class="[$style.form_msg, 'py-1', 'pa-3', 'mb-3']" style="font-weight: normal !important;">
                  以下のフォームからJaM id アカウントの<br>
                  メールアドレスを一時的に変更し、<br>
                  JRE ID への切替を再開してください。<br>
                  （サインイン方法はApple のまま変わり<br>
                  ません）
                </h3>
                <h3 :class="[$style.form_msg, 'py-1', 'pa-3', 'mb-3']">
                  変更後のJaM id メールアドレスが、そ<br>
                  のまま新規作成するJRE ID の登録メー<br>
                  ルアドレスに引き継がれます。<br>
                </h3>
              </v-col>
              <v-col cols="12">
                <MyForm :formKey="formKey" :entity="entity" :input="input" ref="form">
                  <v-container class="pa-0">
                    <v-col cols="12">
                      <ValidationProvider v-slot="{ errors }" :rules="{
    required: 'required',
    email: 'email',
    emailEndDomain: []
  }" name="新しいメールアドレス" vid="newMailAddress" ref="v-newMailAddress">
                        <v-text-field v-model="input['newMailAddress']" name="newMailAddress"
                          label="新しいメールアドレス New Email Address" clearable autocomplete="off" :error-messages="errors[0]"
                          background-color="#fff" :class="$style.textfield">
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>

                  </v-container>
                </MyForm>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <base-btn :disabled="btnDisabled" label="「メールアドレスを変更する」" @click="onConfirm" :style="{
    color: 'white',
    fontSize: '1rem',
    boxShadow: 'none'
  }" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn rounded block large @click="onStop" :style="{
    'background-color': '#ffb95d', 'color': '#fff',
    fontSize: '1rem',
    boxShadow: 'none'
  }">メールアドレスを変更しない<br>※前ページに戻ります</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MyForm from "@/entries/front/components/form";
import ErrorList from "@/entries/front/components/error/error-list";

export default {
  components: {
    MyForm,
    ErrorList
  },

  data() {
    return {
      resourceName: "migration",
      formKey: "account-name",
      title: "アカウント切替手続き画面",
      titleEn: "Email Address Registration",
      input: {
        newMailAddress: ""
      },
      valid: {
        newMailAddress: false
      }
    };
  },
  watch: {
    "input.newMailAddress": {
      handler(value, old) {
        if (value !== old) {
          this.validate("newMailAddress");
        }
      }
    }
  },
  computed: {
    isError() {
      return this.$store.getters[`${this.resourceName}/entity/isError`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/processing`];
    },
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    errorMessages() {
      return this.$store.getters[`${this.resourceName}/entity/errorMessages`];
    },
    btnDisabled() {
      const { newMailAddress } = this.valid;
      return !newMailAddress;
    }
  },
  methods: {
    async validate(refName) {
      const providor = this.$refs[`v-${refName}`];
      const { valid } = await providor.validate();
      this.$nextTick(() => {
        this.valid[refName] = valid;
      });
    },
    popstate() {
      window.history.pushState(null, null, null);
      return;
    },
    onConfirm() {
      this.$store
        .dispatch(`${this.resourceName}/entity/migrationConfirmationCode`, {
          newMailAddress: this.input.newMailAddress
        })
        .then(() => {
          this.$store.commit("form/clearDirty");
          this.$router.push({ name: "migration-auth-code-email" });
        })
        .catch(() => { });
    },
    onStop() {
      this.$router.push({ name: "migration-start" });
    },
  },
  created() {
    // アカウント情報が存在しない場合は、アカウント情報画面に強制的に遷移
    if (!Object.keys(this.entity).length) {
      this.$router.push({ name: "migration-start" });
    } else {
      this.input.newMailAddress = this.entity.newMailAddress;
    }
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
    this.$store.dispatch(`${this.resourceName}/entity/clearError`);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.newMailAddress
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}


.reissue-link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}

.textfield {
  label {
    font-size: 14px !important;
  }
}

.form_msg {
  font-size: 13px !important;
  color: #373e42;
  font-weight: 600;
  line-height: 20px;
  text-align: center !important;
}
</style>
